import React from 'react'
import PageMetaTags from '../../../../components/PageMetaTags'

const DataManagerFAQ = () => (
  <main className='l-main-content' id='home'>
    <PageMetaTags
      title="Data Manager FAQ"
      description="Data Manager: Frequently Asked Questions (FAQ)."
      ogTitle="Data Manager FAQ"
      ogDescription="Data Manager: Frequently Asked Questions (FAQ)."
      ogType="article"
      ogUrl="https://raccoon.pet/shopline/apps/datamanager/faq"
      />
    <section className='section-first section-first_type-2'>
      <div className='container'>
        <h4>Data Manager: Frequently Asked Questions (FAQ)</h4>
        <br/>
        <h5>General Functionality</h5>
        <br/>
        <h6>What is Data Manager?</h6>
        <p>Data Manager is a dedicated import and export tool for Shopline stores. This public application allows you to seamlessly manage product, customer, and metafield data using CSV files.</p>

        <h6>What benefits does Data Manager offer?</h6>
        <p>Data Manager streamlines data management for Shopline stores by simplifying the process of adding or updating large datasets. It also minimizes errors that can occur during manual data entry.</p>
        <br/>
        <h5>Importing Data</h5>
        <br/>
        <h6>What file format does Data Manager use for import?</h6>
        <p>Data Manager relies on CSV (comma-separated values) files for import.</p>

        <h6>Can I download a template to ensure my data is formatted correctly?</h6>
        <p>Absolutely! Data Manager offers downloadable CSV templates specifically designed for Shopline's internal data fields. These templates provide pre-defined headers to guide you in structuring your data for a smooth import process.</p>

        <h6>What are some essential things to consider when formatting my CSV file for import?</h6>
        <ul>
            <li>Organize your data in a clear table format with headers on the first row.</li>
            <li>Ensure headers match the pre-defined names provided in the downloadable templates.</li>
            <li>Use commas (,) to separate values within a record.</li>
            <li>Adhere to the expected data types for each field (e.g., text, numbers, dates). Refer to the templates or user manual for specific data type requirements.</li>
        </ul>

        <h6>How can I import my data using Data Manager?</h6>
        <ul>
            <li>Prepare your CSV file following the formatting guidelines mentioned above.</li>
            <li>Navigate to the "Current Data" tab and select the corresponding block for your data type (e.g., Metafields, Catalog, Customers).</li>
            <li>Choose the appropriate import mode (if applicable).</li>
            <li>Select your prepared CSV file using the file browser or drag-and-drop functionality.</li>
            <li>The system will validate your file. Wait for the results and address any errors identified.</li>
            <li>Once validation is successful, initiate the import process.</li>
        </ul>

        <h6>How can I add metafields to my CSV file?</h6>
        <ul>
            <li>Ensure the metafield is created beforehand using either the metafield definition import process or manual creation.</li>
            <li>Include dedicated columns for the metafields in your CSV file for catalog or customer import.</li>
            <li>Populate the corresponding rows with your metafield data.</li>
            <li>Refer to the user manual for details on metafield column naming structure and data format specifications.</li>
        </ul>
        <br/>
        <h5>Exporting Data</h5>
        <br/>
        <h6>How can I export data using Data Manager?</h6>
        <ul>
            <li>Navigate to the "Current Data" tab and select the corresponding block for your data type (e.g., Metafields, Catalog, Customers).</li>
            <li>Click the "Export" button.</li>
            <li>The file will be automatically downloaded in CSV format.</li>
        </ul>
        <br/>
        <h5>Data Types and Formats</h5>
        <br/>
        <h6>What data types does Data Manager support for import and export?</h6>
        <p>Data Manager supports the data types offered by Shopline for customers, including text, numbers, boolean values, dates, objects, JSON, etc. For specific details and limitations regarding list and object formats within metafields, refer to the user manual.</p>

        <h6>What are some specific considerations for catalog data import?</h6>
        <ul>
            <li>Use the Handle to identify specific products and establish relationships between master and variant products.</li>
            <li>Specify catalog images as a comma-separated list of URLs.</li>
            <li>Define the inventory policy using either "DENY" or "CONTINUE" to determine behavior when stock runs out.</li>
        </ul>

        <h6>How does Data Manager identify customers during import?</h6>
        <ul>
            <li><strong>Shopline Customer Identification:</strong> Shopline defines its own criteria for recognizing unique customers within their platform. Data Manager respects these existing guidelines.</li>
            <li><strong>Preferred Identifiers During Import:</strong> While Data Manager accepts various customer data fields (First Name, Last Name, Email, Phone), it prioritizes Email and Phone number for customer identification during the import process. This aligns with how Shopline might identify customers.</li>
        </ul>

        <h6>What are some additional considerations for customer identification?</h6>
        <ul>
            <li><strong>Uniqueness:</strong> Ensure the Email or Phone number used for identification is unique within your Shopline customer base. Duplicate values can cause errors or unintended data merges.</li>
            <li><strong>Data Completeness:</strong> Although Email and Phone are prioritized, providing additional customer data fields like First Name and Last Name can enhance the accuracy of your import process, especially if Email or Phone numbers are missing or duplicated.</li>
        </ul>

        <h6>Are there any data types that cannot be stored as lists within metafields?</h6>
        <p>Yes, certain data types cannot be stored as lists within metafields. These include multi-line text fields, JSON, booleans, and money.</p>
        <br/>
        <h5>Additional Resources</h5>
        <br/>
        <p>For a more comprehensive guide, refer to the Data Manager User Manual. It provides detailed instructions on using import and export functionalities, along with explanations of data types and specific requirements.</p>
      </div>
    </section>
  </main>
)

export default DataManagerFAQ
